module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PrakharPrakashBhardwaj","short_name":"PrakharBhardwaj","start_url":"/","background_color":"#020c1b","theme_color":"#0a192f","display":"minimal-ui","icon":"src/images/logo.png","icons":[{"src":"images/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png","density":"0.75"},{"src":"images/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png","density":"1.0"},{"src":"images/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png","density":"1.5"},{"src":"images/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png","density":"2.0"},{"src":"images/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png","density":"3.0"},{"src":"images/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png","density":"4.0"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"linkImagesToOriginal":true,"quality":90,"tracedSVG":{"color":"#64ffda"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
